// @file Utils for scheduled posts

import { lang } from '@@/bits/current_locale'
import { toLocalDatetimeString } from '@@/bits/date'
import { __ } from '@@/bits/intl'

/**
 * Formats a scheduled date and time into an object with separate date and time properties.
 *
 * @param {string | null | undefined} scheduledAt - The scheduled date and time to format.
 * @returns {{ date: string; time: string }} - An object with separate date and time properties.
 */
export function formatScheduledDateAndTime(scheduledAt?: string | null): { date: string; time: string } {
  if (scheduledAt === '' || scheduledAt === undefined || scheduledAt === null) {
    return { date: '', time: '' }
  }
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  }

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  return {
    date: new Date(scheduledAt).toLocaleString(lang, dateOptions),
    time: new Date(scheduledAt).toLocaleString(lang, timeOptions),
  }
}

/**
 * Checks if a given schedule datetime is valid.
 *
 * @param {string} scheduledAt - ISO 8601 formatted datetime string in UTC timezone. e.g. '2022-01-01T00:00:00.000Z'
 * @param {Date} [now=new Date()] - The current date and time. Defaults to the current date and time if not provided.
 * @returns {boolean} - Returns true if the schedule datetime is valid, false otherwise.
 */
export function isScheduleDatetimeValid(scheduledAt: string, now: Date = new Date()): boolean {
  const oneYearAhead = new Date(now)
  oneYearAhead.setFullYear(now.getFullYear() + 1)

  const scheduledDatetime = new Date(scheduledAt)
  return !Number.isNaN(scheduledDatetime) && scheduledDatetime >= now && scheduledDatetime < oneYearAhead
}

/**
 * Checks if a scheduled datetiem is invalid and returns an error message.
 * @param {string} scheduledAt - ISO 8601 formatted datetime string in UTC timezone. e.g. '2022-01-01T00:00:00.000Z'
 * @returns {string | null} - Returns validation error message, or null if the scheduled time is valid
 */
export function getScheduleValidationError(scheduledAt: string): string | null {
  const scheduledDatetime = new Date(scheduledAt)

  if (isNaN(scheduledDatetime.valueOf())) {
    return __('Invalid date')
  }

  const now = new Date()
  if (scheduledDatetime < now) {
    return __("Can't schedule in the past")
  }

  const oneYearAhead = new Date(now)
  oneYearAhead.setFullYear(now.getFullYear() + 1)
  if (scheduledDatetime >= oneYearAhead) {
    return __("Can't schedule over one year in the future")
  }

  return null
}

/**
 * Gets the initial string value for a datetime picker input.
 *
 * @param {string | null | undefined} scheduledAt - The scheduled date and time to format.
 * @returns {string} - The initial datetime picker string value.
 */
export function getInitialDatetimePickerString(scheduledAt?: string): string {
  return toLocalDatetimeString(getInitialDatetimePickerDate(scheduledAt))
}

// Returns an initial Date instance for a datetime picker input.
export function getInitialDatetimePickerDate(scheduledAt?: string): Date {
  if (scheduledAt != null) {
    return new Date(scheduledAt)
  }

  // otherwise return the default date as 5mins from now
  const date = new Date()
  date.setMinutes(date.getMinutes() + 5)
  return date
}
